import React from "react";
import styles from "./Footer.module.scss";

import icon_fb from "../../Assets/Svgs/footer_icons/facebook.svg";
import icon_ig from "../../Assets/Svgs/footer_icons/instagram.svg";
import icon_tt from "../../Assets/Svgs/footer_icons/tiktok.svg";
import icon_snd from "../../Assets/Svgs/footer_icons/send.svg";
import icon_in from "../../Assets/Svgs/footer_icons/in.svg";
import { NavLink } from "react-router-dom";
import GoogleTranslate from "language/GoogleTranslate";

function Footer() {
  return (
    <div className={styles.main}>
      <div className={styles.footer_sections}>
        <div className={styles.main_section}>
          <h1>
            Unleash Your <br /> TRENA’thlete
          </h1>

          <h2>Follow us</h2>
          <div className={styles.rxs}>
            <NavLink to="https://www.facebook.com/trena.pro" target="_blank">
              <img src={icon_fb} alt="" />
            </NavLink>
            <NavLink to="https://www.instagram.com/trena.pro" target="_blank">
              <img src={icon_ig} alt="" />
            </NavLink>
            <NavLink to="https://www.tiktok.com/@trena.pro" target="_blank">
              <img src={icon_tt} alt="" />
            </NavLink>
            <NavLink
              to="https://www.linkedin.com/company/trenapro"
              target="_blank"
            >
              <img src={icon_in} alt="" />
            </NavLink>
          </div>
        </div>

        <div className={styles.section}>
          <h2>Explore</h2>
          {/* <NavLink to="/about-us">About Us</NavLink> */}
          <NavLink to="/trainers">Trainers</NavLink>
          <NavLink to="/personal-nutrit">Nutritionists</NavLink>
          <NavLink to="/trenapedia">Trenapedia</NavLink>
          <NavLink to="https://trena.store/" target="_blank" rel="noreferrer">
            Store
          </NavLink>
        </div>

        <div className={styles.section}>
          <h2>Help</h2>
          <NavLink to="/contact-us">Contact Us</NavLink>
          <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
          {/* <p>
            Have questions or need assistance? Reach out to our dedicated
            support team at:
          </p>
          <strong>contact@trena.com.tn</strong> */}
        </div>
        <div className={styles.section}>
          <h2>Be a TRENA’thlete</h2>
          <p>Sign up for our newsletter </p>
          <span>
            to receive exclusive fitness insights, special offers, and more.
          </span>

          <SendNewsletter />
          <GoogleTranslate isUp shorted={false} />
        </div>
      </div>

      <div className={styles.copy_rights}>
        <span className={styles.trenaout}> TRENA </span>{" "}
        <span className={styles.cp_txt}> © 2024. All Rights Reserved. </span>
      </div>
    </div>
  );
}

const SendNewsletter = () => {
  return (
    <div className={styles.newsletter}>
      <input type="text" placeholder="alma.lawson@example.com" />
      <img src={icon_snd} alt="" />
    </div>
  );
};

export default Footer;

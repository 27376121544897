import CloseButton from "Components/Button/CloseButton/CloseButton";
import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import AreaWithLabel from "Components/Input/AreaWithLabel/AreaWithLabel";
import InputWithLabel from "Components/Input/InputWithLabel/InputWithLabel";
import SelectWithLabel from "Components/Input/SelectWithLabel/SelectWithLabel";
import useRefrechUser from "Hooks/useRefrechUser";
import { Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import customAxios from "Utils/axios/axios";
import ViewProfileButton from "./ViewProfileButton";

function CertifsIno() {
  const { user } = useSelector((state) => state.UserReducer);
  const { refrechUser } = useRefrechUser();
  const [loading, setLoading] = React.useState(false);

  const [item, setItem] = useState([]);

  useEffect(() => {
    customAxios.get(`/api/certifs/get-all/${user._id}`).then((res) => {
      console.log(res.data.data);
      setItem(res.data.data);
    });
  }, [user]);

  const handleChange = (event) => {
    const { value } = event.target;
    setItem(value);
  };

  const handleSubmit = async () => {
    try {
      const resp = await customAxios.get(`/api/certifs/get-all/${user._id}`);
      const initItems = resp.data.data;

      for (let i = 0; i < initItems.length; i++) {
        let currentItem = initItems[i];
        let found = false;
        for (let j = 0; j < item.length; j++) {
          if (currentItem._id === item[j]._id) {
            found = true;
          }
        }
        if (!found) {
          await customAxios.delete(`/api/certifs/delete/${currentItem._id}`);
        }
      }

      for (let i = 0; i < item.length; i++) {
        if (item[i]._id) {
          await customAxios.put(`/api/certifs/update/${item[i]._id}`, {
            ...item[i],
            coach: user._id,
          });
        } else {
          await customAxios.post(`/api/certifs/create`, {
            ...item[i],
            coach: user._id,
          });
        }
      }
      toast.success("Updated successfully");
    } catch (error) {
      toast.error("something happened");
      console.log(error);
    }
  };

  return (
    <div className="">
      <MultiCertifs
        name="certifs"
        value={item}
        onChange={handleChange}
        loading={loading}
        setLoading={setLoading}
      />

      <div className="flex items-center justify-end gap-2 mt-[2vw]">
        <ViewProfileButton loading={loading} />
        <SubmitBlack onClick={handleSubmit} loading={loading}>
          SAVE
        </SubmitBlack>
      </div>
    </div>
  );
}

export const MultiCertifs = ({
  onChange = () => {},
  name: itemName = "",
  value = [],
  loading,
  setLoading,
}) => {
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    setCerts(value);
  }, [value]);

  const AddCert = () => {
    setCerts([
      ...certs,
      {
        title: "",
        description: "",
        type: "Certification",
      },
    ]);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const newCerts = [...certs];
    newCerts[index][name] = value;
    setCerts([...newCerts]);
    onChange({ target: { itemName, value: certs } });
  };

  const RemoveCert = (index) => {
    certs.splice(index, 1);
    setCerts([...certs]);
    onChange({ target: { itemName, value: certs } });
  };

  return (
    <div className="w-full">
      {certs.map((tag, index) => {
        return (
          <div
            key={index}
            className="mt-[2vw] px-[1.5vw] py-[1.5vw] rounded-[1vw] w-full border-border border relative"
          >
            <InputWithLabel
              label="Certif Title"
              name="title"
              value={tag.title}
              placeholder={`Certif Title ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />
            <SelectWithLabel
              label="Type (Certification / Diplome)"
              value={tag.type}
              className="mt-[2vw]"
              name="type"
              onChange={(e) => {
                handleChange(
                  { target: { name: "type", value: e.target.value } },
                  index
                );
              }}
              options={[
                {
                  label: "Certification",
                  value: "Certification",
                },
                {
                  label: "Diplome",
                  value: "Diplome",
                },
              ]}
              placeholder="Select"
              style={{ width: "100%" }}
            />
            <AreaWithLabel
              key={index}
              label="Certif Description"
              name="description"
              className="mt-[2vw]"
              value={tag.description}
              rows={3}
              placeholder={`Certif Description ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />

            <CloseButton
              onClick={() => {
                RemoveCert(index);
              }}
            />
          </div>
        );
      })}

      <SubmitBlack
        onClick={AddCert}
        className="ml-auto mt-[1vw] !w-full !rounded-[0.5vw] font-medium !text-black !bg-gray-50 hover:!bg-gray-200 transition-all duration-200"
        loading={loading}
        Icon={Plus}
      >
        Add Certificaton / Diplome
      </SubmitBlack>
    </div>
  );
};

export default CertifsIno;

import React, { useEffect, useState } from "react";
import st from "./styles.module.scss";
import { partners } from "./data";
import { DuplicateArray } from "Functions/Arrays";
import customAxios from "Utils/axios/axios";
import ImgBack from "Components/Image/ImgBack";

const DuplicateToFitt = (data = []) => {
  return DuplicateArray(data, Math.floor(24 / data.length));
};

const MainPart = () => {
  const [partners2, setPartners] = useState([]);

  useEffect(() => {
    customAxios.get("/api/partners/get-published").then((resp) => {
      let data = resp.data.data; 

      setPartners(DuplicateToFitt(data));
    });
  }, []);

  useEffect(() => {
    console.log(partners2);
  }, [partners2]);

  return (
    <>
      {partners2.map((item, index) => (
        <div key={index} className={st.slide}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <ImgBack src={item.img} alt="" />
          </a>
        </div>
      ))}
    </>
  );
};

function InfinitySlider() {
  return (
    <div className={st.slider}>
      <div className={st["slide-track"]}>
        <MainPart />
      </div>
    </div>
  );
}

export default InfinitySlider;

import React from "react";
import styles from "Pages/Auth/Login.module.scss";

import TextInput from "Components/Input/TextInput/TextInput";
import gender from "Assets/Data/Gender.json";
import CheckBoxes from "Components/Form/CheckBoxes/CheckBoxes";

export const SubInfoPart = ({ form, handleChange, haveError }) => {
  return (
    <>
      <TextInput
        type="number"
        className={styles["w-50-keep"]}
        placeholder="Height (cm)"
        required={true}
        value={form.height}
        name="height"
        onChange={handleChange}
        isInvalid={form.height <= 0}
        triggerInvalid={haveError}
      />

      <TextInput
        type="number"
        className={styles["w-50-keep"]}
        placeholder="Weight (kg)"
        value={form.weight}
        required={true}
        name="weight"
        onChange={handleChange}
        isInvalid={form.weight <= 0}
        triggerInvalid={haveError}
      />

      <CheckBoxes
        label="Select Your Gender"
        name="gender"
        value={form.gender}
        onChange={handleChange}
        maxSelects={1}
        isText={true}
        options={gender}
        required={true}
        isInvalid={form.gender.length === 0}
        triggerInvalid={haveError}
      />

      {/* <SelectInput
        type="text"
        className={styles["w-100"]}
        placeholder="Please select a city"
        value={form.city}
        name="city"
        items={location}
        onChange={handleChange}
      /> */}
    </>
  );
};

export default SubInfoPart;

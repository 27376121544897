import Flex from "Components/Flex/Flex";
import React from "react";
import styles from "../FindTrainerPopup.module.scss";
import { NavLink } from "react-router-dom";
import { useUser } from "Store/hooks/useUser";
import Button from "Components/Button/Button";

const FinaliseCoachFilter = ({
  handle_prev = () => {},
  handle_close = () => {},
  theme = "main",
}) => {
  const { user } = useUser();

  return (
    <Flex flex="between" className={styles.main}>
      <div className={styles.header}>
        <h2>
          Thank you, {user.fullname}! that’s are all <br /> the info we need for
          now !
        </h2>
        <p className={styles.desc}>
          Welcome to <strong>TRENA</strong>, where we're more than just a
          fitness platform; we're <br /> your dedicated partner in crafting a
          healthier life. Our mission <br /> encompasses three vital pillars:
        </p>
      </div>

      <br />

      <Flex flex="end" className={styles.footer}>
        <Button onClick={handle_prev} color="black">
          Previus
        </Button>
        <Button onClick={handle_close}>
          <NavLink to="/trainers" color={theme}>
            Finish
          </NavLink>
        </Button>
      </Flex>
    </Flex>
  );
};
export default FinaliseCoachFilter;

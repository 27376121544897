import React from "react";
import styles from "Pages/Auth/Login.module.scss";
import TextInput from "Components/Input/TextInput/TextInput";
import SelectInput from "Components/Input/TextInput/SelectInput";
import Level from "Assets/Data/Level.json";
import CheckBoxes from "Components/Form/CheckBoxes/CheckBoxes";

const FitnessGoalsOptions = [
  { label: "Weight Loss", value: "Weight Loss" },
  { label: "Muscle Building", value: "Muscle Building" },
  { label: "Endurance Training", value: "Endurance Training" },
  { label: "Flexibility Improvement", value: "Flexibility Improvement" },
  { label: "Sports Performance", value: "Sports Performance" },
];

export const PartOne = ({ form, handleChange, haveError }) => {
  return (
    <>
      <CheckBoxes
        label="Set Your Goals"
        name="goals"
        value={form.goals}
        onChange={handleChange}
        options={FitnessGoalsOptions}
        required={true}
        isInvalid={form.goals.length === 0}
        triggerInvalid={haveError}
      />

      <CheckBoxes
        label="Pick Your Level"
        name="experience_level"
        value={form.experience_level}
        onChange={handleChange}
        maxSelects={1}
        isText={true}
        options={Level}
        required={true}
        isInvalid={form.experience_level.length === 0}
        triggerInvalid={haveError}
      />
    </>
  );
};

export default PartOne;

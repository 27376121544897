export const plans = {
  first: {
    title: "Free",
    comingSoon: false,
    icon: "/svgs/icons/bolt.png",
    description: `Free Subscription "Your health journey starts here."`,
    prices: { month: 0, year: 0, actualPrice: 0 },
    features: {
      main: [
        "**Basic Features:** Access to general fitness tips, blog posts, and nutrition guides.",
        "**Explore Trenapedia:** Browse transformation stories, fitness blogs, and expert articles.",
        "**Limited Programs:** Access to a few workout programs and diet plans.",
        "**Community Forum:** Engage with fellow fitness enthusiasts and get tips or share experiences.",
      ],
      more: [
        "**Introductory Videos:** Access free introductory videos from trainers on specific exercises or nutrition basics.",
      ],
    },
  },
  secon: {
    title: "Pro",
    comingSoon: true,
    icon: "/svgs/icons/muslce.png",
    description: `Pro Subscription "Unlock more, achieve more with Pro."`,
    prices: { month: 35, year: 35 * 10, actualPrice: 35 * 12 },
    features: {
      main: [
        "**Access to Coaches:** Access profiles of certified fitness coaches with specialized expertise.",
        "**Priority Booking:** Get ahead in booking your one-on-one coaching sessions.",
        "**Exclusive Content:** Unlock advanced workout routines, nutrition tips, and transformation challenges.",
        "**Goal-Oriented Programs:** Access tailored programs focused on specific fitness goals such as fat loss, muscle gain, or flexibility.",
      ],
      more: [
        "**Progress Reports:** Receive monthly progress reports from your coach based on feedback and routine adherence.",
        "**Discounts on Premium Content:** Get exclusive discounts on new courses, challenges, and workshops offered on TRENA.",
      ],
    },
  },
  third: {
    title: "Premium",
    comingSoon: true,
    icon: "/svgs/icons/shine.png",
    description: `Premium Subscription "Premium results, premium service."`,
    prices: { month: 69, year: 69 * 10, actualPrice: 69 * 12 },
    features: {
      main: [
        "**All Pro Features:** Enjoy everything included in the Pro plan.",
        "**Personalized Nutrition Plans:** Receive customized diet plans from certified nutritionists.",
        "**Direct Messaging:** Have quick access to trainers and nutritionists via direct messaging for ongoing support.",
        "**Unlimited Coaching Sessions:** Book unlimited coaching and nutrition sessions for ongoing guidance and motivation.",
      ],
      more: [
        "**Exclusive Group Classes & Events:** Participate in exclusive live group classes and webinars led by expert trainers.",
        "**Advanced Fitness Challenges:** Access special fitness challenges with rewards or certificates for completion.",
        "**Merchandise Discounts:** Enjoy up to 5% off all TRENA merchandise, including activewear, fitness gear, and wellness supplements.",
        "**Wellness Dashboard:** Unlock advanced metrics and insights about your fitness journey, including calorie tracking, workout intensity, and personalized tips for improvement.",
      ],
    },
  },
};

import { useEffect } from "react";

function useHTTPS() {
  useEffect(() => {
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.href = window.location.href.replace(
        "http://",
        "https://"
      );
    }
  }, []);
}

export default useHTTPS;

import "./App.scss";
import Nabbar from "./Layouts/Navbar/Navbar";
import Home from "./Pages/HomePage/Home";
import Footer from "./Layouts/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./Pages/AboutUs/AboutUs";
import PersonalCoach from "./Pages/PersonalCoach/PersonalCoach";
import OneCoach from "./Pages/OneCoach/OneCoach";
import TrenaPedia from "./Pages/TrenaPedia/TrenaPedia";
import OneBlog from "./Pages/OneBlog/OneBlog";
import useScrollToTopOnRouteChange from "./Hooks/useToTop";
import ContactUs from "./Pages/ContactUs/ContactUs";
import { Register, Login } from "./Pages/Auth/Auth";
import UseHaveOneOfTheLinks from "./Hooks/UseHaveOneOfTheLinks";
import Toast from "./Utils/toast/Toast";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetUserByToken } from "./Store/user.reducer";
import Profile from "./Pages/Profile/Profile";
import TC from "./Pages/TC/TC";
import PersonalNutrit from "./Pages/PersonalCoach/PersonalNutrit";
import OneNutri from "./Pages/OneCoach/OneNutri";
import MySettings from "./Pages/Dashboard/MySettings/MySettings";
import PricingPage from "Pages/PricingPage/PricingPage";
import Logout from "Pages/Dashboard/Logout/Logout";
import useHTTPS from "Hooks/useHTTPS";

function App() {
  useScrollToTopOnRouteChange();
  useHTTPS();
  const dispatch = useDispatch();
  const with_layout = UseHaveOneOfTheLinks([
    "/register",
    "/404",
    "/login",
    "/dashboard",
  ]);

  useEffect(() => {
    dispatch(GetUserByToken(() => {}));
  }, []);

  return (
    <div className="App">
      <Toast />
      {!with_layout && <Nabbar />}

      <Routes>
        <Route path="/trainers" element={<PersonalCoach />} />
        <Route path="/personal-nutrit" element={<PersonalNutrit />} />
        <Route path="/trenapedia" element={<TrenaPedia />} />
        <Route path="/trenapedia/:id" element={<OneBlog />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TC />} />
        <Route path="/trainers/:id" element={<OneCoach />} />
        <Route path="/personal-nutrit/:id" element={<OneNutri />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/pricing" element={<PricingPage />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/404" element={<h1>hello this is 404</h1>} />*/}

        {/* PROFILE AND DASHBOARD PART */}
        <Route path="/dashboard/my-settings" element={<MySettings />} />
        <Route path="/dashboard/logout" element={<Logout />} />
      </Routes>

      {!with_layout && <Footer />}
    </div>
  );
}

export default App;

export const MakeArray = (rates) => {
  let arr = [];
  for (let i = 0; i < rates; i++) {
    arr.push(true);
  }

  for (let i = 0; i < 5 - rates; i++) {
    arr.push(false);
  }

  return arr;
};

// a function that duplicate the table based on a param

export const DuplicateArray = (arr, times) => {
  let newArr = [];
  for (let i = 0; i < times; i++) {
    newArr = [...newArr, ...arr];
  }
  return newArr;
};

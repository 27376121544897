// src/hooks/useGoogleAuth.js
import { useState } from "react";
import { auth, provider, signInWithPopup } from "../Functions/firebase";

const useGoogleAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loginWithGoogle = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();
      setUser({ ...user, idToken });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const logout = async () => {
    await auth.signOut();
    setUser(null);
  };

  return { user, loading, error, loginWithGoogle, logout };
};

export default useGoogleAuth;

import React from "react";
import { WithRxsBtn } from "./Auth";
import google_icon from "../../Assets/Svgs/iicon_rxs/google.svg";
import { useDispatch } from "react-redux";
import { ActionLoginGO } from "../../Store/user.reducer";
import useGoogleAuth from "Hooks/useGoogleAuth";

function GoogleAuth({ txt }) {
  const dispatch = useDispatch();
  const { loginWithGoogle } = useGoogleAuth();

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     dispatch(ActionLoginGO(tokenResponse.access_token, () => {}));
  //   },
  // });

  return (
    <>
      <WithRxsBtn img={google_icon} txt={txt} onClick={loginWithGoogle} />
    </>
  );
}

export default GoogleAuth;

import React, { useEffect, useState } from "react";
import styles from "./CheckBoxes.module.scss";

const CheckBoxes = ({
  label = "",
  name = "",
  onChange = () => {},
  options = [],
  value = [],
  maxSelects = -1,
  isText = false,
  required = false,
  isInvalid = false,
  triggerInvalid = false,
}) => {
  const handleSelect = (item) => {
    const { value: itemValue } = item;

    let newSelectedValues;

    if (isText) {
      // Text mode: Return the selected value as a string
      newSelectedValues = value === itemValue ? "" : itemValue; // Deselect if already selected
    } else {
      // Array mode
      if (value.includes(itemValue)) {
        // Remove the value if it's already selected
        newSelectedValues = value.filter((val) => val !== itemValue);
      } else {
        // Add the value if it's not selected
        if (maxSelects > 0 && value.length >= maxSelects) {
          // Apply FIFO: Remove the first selected item to make room
          newSelectedValues = [...value.slice(1), itemValue];
        } else {
          newSelectedValues = [...value, itemValue];
        }
      }
    }

    // Trigger the onChange callback with the desired format
    onChange({
      target: {
        value: newSelectedValues,
        name: name,
      },
    });
  };

  const isSelected = (item) => {
    if (isText) {
      return value === item.value; // Text mode: Check if value matches
    }
    return value.includes(item.value); // Array mode
  };

  return (
    <div className={styles.checkboxes}>
      <h3 className={isInvalid && triggerInvalid ? styles.invalid : ""}>
        {label}
        {required && <span className="red">*</span>}
      </h3>
      <div className={styles.items}>
        {options.map((item, index) => (
          <ItemSelect
            key={index}
            item={item}
            handleSelect={handleSelect}
            isSelected={isSelected}
          />
        ))}
      </div>
    </div>
  );
};

const ItemSelect = ({
  item = { label: "", value: "" },
  handleSelect = () => {},
  isSelected = () => {},
}) => {
  const { label } = item;

  const clickSelect = () => {
    handleSelect(item);
  };

  const check = isSelected(item);

  return (
    <div
      onClick={clickSelect}
      className={`${styles.item_select} ${check ? styles.checked : ""}`}
    >
      <div className={styles.item_select_text}>{label}</div>
    </div>
  );
};

export default CheckBoxes;

import React, { useEffect } from "react";
import styles from "./Navbar.module.scss";
import logo from "../../Assets/Svgs/logo_white.svg";
import beta from "../../Assets/Svgs/beta.png";

import useIsCurrentUrl from "../../Hooks/UseIsCurrentUrl";
import useCondRet from "../../Hooks/UseCondRet";

import { NavLink } from "react-router-dom";
import Button from "../../Components/Button/Button";
import Flex from "../../Components/Flex/Flex";
import { useOnScroll } from "../../Hooks/useOnScroll";
import usePopup from "../../Hooks/usePupup";
import RegisterPopup from "../../Pages/Pupups/RegisterPopup/RegisterPopup";
import LoginPopup from "../../Pages/Pupups/LoginPopup/LoginPopup";
import { useSelector } from "react-redux";
import useResolution from "../../Hooks/useResolution";
import NavbarMobile from "./NavbarMobile";
import ConnectedUser from "./ConnectedUser";
import GoogleTranslate from "language/GoogleTranslate";

function NavbarPc() {
  const [openR, handle_openR, handle_closeR] = usePopup();
  const [openL, handle_openL, handle_closeL] = usePopup();
  const { is_connected } = useSelector((state) => state.UserReducer);

  let is_home = useCondRet(useIsCurrentUrl("/"), "", styles.not_in_home);
  const is_scrolled = useOnScroll(80) ? styles.is_scrolled : "";

  useEffect(() => {
    if (is_connected) {
      handle_closeL();
      handle_closeR();
    }
  }, [is_connected]);

  return (
    <div className={`${styles.main} ${is_home} ${is_scrolled}`}>
      <RegisterPopup
        open={openR}
        handle_close={handle_closeR}
        openOther={() => {
          handle_closeR();
          handle_openL();
        }}
      />
      <LoginPopup
        open={openL}
        handle_close={handle_closeL}
        openOther={() => {
          handle_closeL();
          handle_openR();
        }}
      />

      <div className={styles.content}>
        <NavLink to="/" className={styles.logo}>
          <div className="relative">
            <img src={logo} alt="" className={styles.actual_logo} />
            <div className="absolute right-[0px] top-[90%] ">
              <img src={beta} alt="beta" className={styles.beta_logo} />
            </div>
          </div>
        </NavLink>

        <div className={styles.links}>
          {/* <NavLink to="/about-us" className={styles.link__item}>
            About Us
          </NavLink> */}

          <div className={`${styles.link__item} ${styles.no_anim}`}>
            Wellness Experts
            <div className={styles.more}>
              <NavLink to="/trainers" className={styles.sub_link}>
                Trainers
              </NavLink>
              <NavLink
                to="/personal-nutrit"
                className={`${styles.sub_link} ${styles.hover_green}`}
              >
                Nutritionists
              </NavLink>
            </div>
          </div>

          <NavLink to="/pricing" className={styles.link__item}>
            Pricing
          </NavLink>

          <NavLink to="/trenapedia" className={styles.link__item}>
            Trenapedia
          </NavLink>
          <div className={`${styles.link__item} ${styles.store}`}>
            <NavLink to="https://trena.store/" target="_blank" rel="noreferrer">
              Store
            </NavLink>
          </div>

          <NavLink to="/contact-us" className={styles.link__item}>
            Contact us
          </NavLink>
        </div>

        <Flex className={styles.signup}>
          <div className={styles.lang}>
            <GoogleTranslate shorted={true} />
          </div>

          {!is_connected && (
            <div className={styles.btn_register} onClick={handle_openL}>
              LOGIN
            </div>
          )}
          {!is_connected && (
            <Button size="small" className={styles.btn} onClick={handle_openR}>
              JOIN
            </Button>
          )}

          <ConnectedUser />

          {/* {is_connected && (
            <NavLink to="/profile">
              <Button size="small" className={styles.btn}>
                My Profile
              </Button>
            </NavLink>
          )} */}
        </Flex>
      </div>
    </div>
  );
}

export const GapNavbarSpacer = () => {
  return <div className={styles.gap_spacer}></div>;
};

const Navbar = () => {
  const { md } = useResolution();

  return md ? <NavbarMobile /> : <NavbarPc />;
};

export default Navbar;

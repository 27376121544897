import AreaWithLabel from "Components/Input/AreaWithLabel/AreaWithLabel";
import InputWithLabel from "Components/Input/InputWithLabel/InputWithLabel";
import TowItems from "Components/Input/TowItems/TowItems";
import { makeDate2 } from "Functions/Dates";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Gender from "../../../../Assets/Data/Gender.json";
import Location from "../../../../Assets/Data/Location.json";
import SelectWithLabel from "Components/Input/SelectWithLabel/SelectWithLabel";
import ImagesWithLabel from "Components/Input/ImagesWithLabel/ImagesWithLabel";
import customAxios from "Utils/axios/axios";
import toast from "react-hot-toast";
import useRefrechUser from "Hooks/useRefrechUser";
import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import ViewProfileButton from "./ViewProfileButton";

function Generalinfo({ role = "" }) {
  const { user } = useSelector((state) => state.UserReducer);
  const { refrechUser } = useRefrechUser();
  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState({
    fullname: "",
    Location: "",
    email: "",
    phoneNumber: "",
    sex: "",
    birthDate: new Date(),
    speciality: "",
    DateStartExp: new Date(),
    About: "",
    Images: [],
  });

  useEffect(() => {
    if (user) {
      setForm({ ...user });
    }
  }, [user]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    customAxios
      .put(`/api/${role}/update/${user._id}`, {
        ...form,
      })
      .then((res) => {
        toast.success("Updated successfully");
        refrechUser();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error updating data");
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <TowItems>
        <InputWithLabel
          label="Full name"
          placeholder="Enter your full name"
          name="fullname"
          value={form.fullname}
          onChange={handleChange}
        />
        <SelectWithLabel
          label="Location"
          options={Location}
          placeholder="Enter your location"
          name="Location"
          value={form.Location}
          onChange={handleChange}
        />
      </TowItems>

      <TowItems>
        <InputWithLabel
          className="!w-1/2"
          label="Email"
          type="email"
          placeholder="Enter your email"
          name="email"
          value={form.email}
          onChange={handleChange}
        />
        <InputWithLabel
          className="!w-1/2"
          label="Phone Number"
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          placeholder="Enter your phone number"
          name="phoneNumber"
          value={form.phoneNumber}
          onChange={handleChange}
        />
      </TowItems>

      <TowItems>
        <InputWithLabel
          className="!w-1/2"
          type="date"
          label="Date of birth"
          placeholder="Enter your date of birth"
          name="birthDate"
          value={makeDate2(form.birthDate)}
          onChange={handleChange}
        />
        <SelectWithLabel
          className="!w-1/2"
          label="Gender"
          options={Gender}
          placeholder="Enter your gender"
          name="sex"
          value={form.sex}
          onChange={handleChange}
        />
      </TowItems>

      <TowItems>
        <InputWithLabel
          className="!w-1/2"
          label="Specialty"
          placeholder="Enter your specialty"
          name="speciality"
          value={form.speciality}
          onChange={handleChange}
        />
        <InputWithLabel
          className="!w-1/2"
          type="date"
          label="Date of start experience"
          placeholder="Enter your date of start experience"
          name="DateStartExp"
          value={makeDate2(form.DateStartExp)}
          onChange={handleChange}
        />
      </TowItems>

      <TowItems>
        <AreaWithLabel
          label="About"
          placeholder="Enter your about"
          rows={6}
          name="About"
          value={form.About}
          onChange={handleChange}
        />
      </TowItems>

      <TowItems>
        <ImagesWithLabel
          label="Your images"
          name="Images"
          value={form.Images}
          onChange={handleChange}
        />
      </TowItems>

      <div className="flex items-center justify-end gap-2">
        <ViewProfileButton loading={loading} />
        <SubmitBlack onClick={handleSubmit} loading={loading}>
          SAVE
        </SubmitBlack>
      </div>
    </div>
  );
}

export default Generalinfo;

import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import React from "react";
import { Eye } from "lucide-react";
import useUser from "Hooks/useUser";

function ViewProfileButton({ loading }) {
  const { user, isCoach, isNutritionist } = useUser();

  const PreviewProfileLink = () => {
    if (isCoach) {
      // window.open(`/#/trainers/${user._id}`, "_blank");
      window.open(`/#/trainers/${user._id}`, "_blank");
    } else if (isNutritionist) {
      // window.open(`/#/personal-nutrit/${user._id}`, "_blank");
      window.open(`/#/personal-nutrit/${user._id}`, "_blank");
    }
  };

  return (
    <SubmitBlack
      onClick={PreviewProfileLink}
      loading={loading}
      Icon={Eye}
      className="!px-[3vw]"
      color="black"
      bgcolor="gray-200"
    >
      Preview
    </SubmitBlack>
  );
}

export default ViewProfileButton;

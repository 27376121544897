import { useSelector } from "react-redux";

function useUser() {
  const {
    payload = false,
    is_connected = false,
    user: {
      type = "",
      _id = "",
      fullname = "",
      email = "",
      phoneNumber = "",
      MainImg = "",
      createdAt = "",
      updatedAt = "",
    },
  } = useSelector((state) => state.UserReducer);

  return {
    payload,
    is_connected,
    user: {
      type,
      _id,
      fullname,
      email,
      phoneNumber,
      MainImg,
      createdAt,
      updatedAt,
    },
    isCoach: type === "COACH",
    isUser: type === "USER",
    isNutritionist: type === "NUTRITIONIST",
  };
}

export default useUser;

import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";

function DateInput({
  type = "text",
  placeholder = "",
  value = "",
  name = "",
  onChange = () => {},
  className = "",
  disabled = false,
  required = false,
  isInvalid = false,
  triggerInvalid = false,
}) {
  const inputRef = useRef(null); // Ref for the input field
  const spanRef = useRef(null); // Ref for the placeholder span
  const [didFocus, setDidFocus] = React.useState(false);

  useEffect(() => {
    setDidFocus(triggerInvalid);
  }, [triggerInvalid]);

  useEffect(() => {
    if (value) {
      if (spanRef.current) {
        spanRef.current.style.display = "none"; // Hide the placeholder when input is focused
        inputRef.current.type = "date"; // Change the input type to date
      }
    }
  }, [value, spanRef]);

  const handlePlaceholderClick = () => {
    if (inputRef.current) {
      inputRef.current.type = "date"; // Change the input type to date
      inputRef.current.focus(); // Focus the input field when placeholder is clicked
    }

    if (spanRef.current) {
      spanRef.current.style.display = "none"; // Hide the placeholder when input is focused
    }
  };

  const handleInputChange = (e) => {
    onChange(e); // Trigger the onChange callback
    // if (spanRef.current) {
    //   spanRef.current.style.display = e.target.value ? "none" : "block"; // Show the placeholder if the input is empty
    // }
  };

  const handleInputBlur = () => {
    // Check if the input is empty when it loses focus
    if (spanRef.current && inputRef.current && !inputRef.current.value) {
      spanRef.current.style.display = "block"; // Show the placeholder again
      inputRef.current.type = "text"; // Change the input type to text
    }
  };

  return (
    <div className={`${className} relative`}>
      <span
        className={`${styles.placeholder} ${
          isInvalid && didFocus ? styles.invalid : ""
        }`}
        ref={spanRef} // Attach the ref to the span element
        onClick={handlePlaceholderClick} // Attach click handler to the placeholder
      >
        {placeholder}
        {required && <span className="red">*</span>}
      </span>
      <input
        ref={inputRef} // Attach the ref to the input element
        // type={type}
        className={`${styles.input} ${
          isInvalid && didFocus ? styles.invalid : ""
        }`}
        value={value}
        name={name}
        disabled={disabled}
        onChange={handleInputChange}
        onBlur={handleInputBlur} // Handle blur event
      />
    </div>
  );
}

export default DateInput;

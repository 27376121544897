import InputWithLabelAndIcon from "Components/Input/InputWithLabelAndIcon/InputWithLabelAndIcon";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import customAxios from "Utils/axios/axios";
import toast from "react-hot-toast";
import useRefrechUser from "Hooks/useRefrechUser";
import SubmitBlack from "Components/Button/SubmitBlack/SubmitBlack";
import ViewProfileButton from "./ViewProfileButton";

function SocialInfo({ role = "" }) {
  const { user } = useSelector((state) => state.UserReducer);
  const { refrechUser } = useRefrechUser();
  const [loading, setLoading] = React.useState(false);

  const [form, setForm] = React.useState({
    Instagram: "",
    Youtube: "",
    Facebook: "",
    Twitter: "",
    Linkedin: "",
    PortfolioUrl: "",
    SnapChat: "",
    TikTok: "",
  });

  useEffect(() => {
    if (user) {
      setForm({ ...user });
    }
  }, [user]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    customAxios
      .put(`/api/${role}/update/${user._id}`, {
        ...form,
      })
      .then((res) => {
        toast.success("Updated successfully");
        refrechUser();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error updating data");
        setLoading(false);
      });
  };

  return (
    <div className="w-full">
      <InputWithLabelAndIcon
        label="Facebook URL"
        placeholder="Enter your Facebook URL"
        name="Facebook"
        icon="/svgs/rxs/fb.svg"
        value={form.Facebook}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="Instagram URL"
        placeholder="Enter your Instagram URL"
        name="Instagram"
        icon="/svgs/rxs/st.svg"
        value={form.Instagram}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="Youtube URL"
        placeholder="Enter your Youtube URL"
        name="Youtube"
        icon="/svgs/rxs/yt.svg"
        value={form.Youtube}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="Twitter / X URL"
        placeholder="Enter your Twitter URL"
        name="Twitter"
        icon="/svgs/rxs/tw.svg"
        value={form.Twitter}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="Linkedin URL"
        placeholder="Enter your Linkedin URL"
        name="Linkedin"
        icon="/svgs/rxs/in.svg"
        value={form.Linkedin}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="SnapChat URL"
        placeholder="Enter your SnapChat URL"
        name="SnapChat"
        icon="/svgs/rxs/sn.svg"
        value={form.SnapChat}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="TikTok URL"
        placeholder="Enter your TikTok URL"
        name="TikTok"
        icon="/svgs/rxs/tk.svg"
        value={form.TikTok}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <InputWithLabelAndIcon
        label="Portfolio URL"
        placeholder="Enter your Portfolio URL"
        name="PortfolioUrl"
        icon="/svgs/rxs/ww.svg"
        value={form.PortfolioUrl}
        className="mb-[2vw]"
        type="url"
        onChange={handleChange}
      />

      <div className="flex items-center justify-end gap-2">
        <ViewProfileButton loading={loading} />
        <SubmitBlack onClick={handleSubmit} loading={loading}>
          SAVE
        </SubmitBlack>
      </div>
    </div>
  );
}

export default SocialInfo;
